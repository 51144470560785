const Subject = (props) => {
  const id = props.lessonId;
  const name = props.lessonName;
  const lessonHandler = props.lessonHandler;
  return (
    <div key={id} onClick={() => lessonHandler(name)}>
      {name}
    </div>
  );
};

export default Subject;
