import { FiInfo, FiMail, FiSettings } from "react-icons/fi";
import OptionContent from "./OptionContent";
import "./Options.css";

const Options = (props) => {
  const option = props.option.trim();
  const changeOptionHandler = props.changeOption;

  return (
    <>
      <div className="options-wrapper">
        <FiMail
          className={`options-icon ${option === "mail" && "active"}`}
          onClick={() => changeOptionHandler("mail")}
        />
        <FiSettings
          className={`options-icon ${option === "settings" && "active"}`}
          onClick={() => changeOptionHandler("settings")}
        />
        <FiInfo
          className={`options-icon ${option === "visual" && "active"}`}
          onClick={() => changeOptionHandler("visual")}
        />
      </div>
      <OptionContent option={option} />
    </>
  );
};

export default Options;
