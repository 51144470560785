import "./NavBar.css";

const NavBar = (props) => {
  const checkStyle = (style) => (typeof style != "string" ? "inherit" : style);

  const styles = {
    width: "100vw",
    height: "5em",
    color: checkStyle(props.color),
    backgroundColor: checkStyle(props.backgroundColor),
  };
  return (
    <div style={styles} className="nav-bar__ui">
      {props.children}
    </div>
  );
  return;
};

export default NavBar;
