import "../../theme.css";
import { FaBars } from "react-icons/fa";
import NavBar from "../UI/NavBar";

const Nav = (props) => {
  const title = props.lesson;
  const openSideBar = props.sideIsOpen;
  return (
    <NavBar backgroundColor="var(--main)">
      <FaBars className="icon-menu" onClick={openSideBar} />
      <h1 className="title">{title}</h1>
    </NavBar>
  );
};

export default Nav;
