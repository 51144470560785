import db from "./firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { useState, useEffect } from "react";

const Contents = (lesson = null, subject = null) => {
  let path = "";
  if (lesson === null) {
    path = "lessons";
  } else if (subject === null) {
    path = `lessons/${lesson}/subjects`;
  } else {
    path = `lessons/${lesson}/subjects/${subject}/contents`;
  }
  const [content, setContents] = useState([]);
  const usersCollectionReference = collection(db, path);

  useEffect(() => {
    const getContents = async () => {
      const data = await getDocs(usersCollectionReference);
      setContents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getContents();
  }, []);
  return content;
};

export default Contents;
