import Contents from "../../firebase-db";
import GridList from "../UI/GridList";
import ContentWrapper from "../UI/ContentWrapper";

const RenderFraction = (props) => {
  //* This function checks if the given value is undefined. It helps at rendering the subject fragments *//
  const isUndef = (value) => (value == undefined ? true : false);

  const content = Contents(props.lesson, props.subject); //?Gets the firebase contents

  return content.map((fraction) => (
    <ContentWrapper key={fraction.id}>
      <GridList display="tactical" title={fraction.title}>
        {!isUndef(fraction.text1) && <p>{fraction.text1}</p>}

        {!isUndef(fraction.img) && (
          <img src={fraction.img} className="subject-img" />
        )}

        {(isUndef(fraction.img) || isUndef(fraction.link)) && <br />}

        {!isUndef(fraction.link) && (
          <a href={fraction.link} target="_blank">
            {!isUndef(fraction.linktext) ? fraction.linktext : "Link"}
          </a>
        )}

        {!isUndef(fraction.text2) && <p>{fraction.text2}</p>}
      </GridList>
    </ContentWrapper>
  ));
};

export default RenderFraction;
