import "./GridList.css";

const GridList = (props) => {
  return (
    <>
      <center>
        <h3 className="list-title">{props.title}</h3>
      </center>
      <div
        className={`${
          props.display === "tactical" ? "grid-tactical" : "grid-list"
        }`}
      >
        {props.children}
      </div>
    </>
  );
};

export default GridList;
