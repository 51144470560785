import "./EmailForm.css";

const EmailForm = (props) => {
  const email = props.email ? props.email : "test@test.com";
  const method = props.method ? props.method : "POST";
  return (
    <form
      action={`mailto:${email}`}
      method={method}
      className="mail-form"
      encType="text/plain"
    >
      <label htmlFor="name">Imie:</label>
      <input type="text" id="name" name="name" required />
      <br />
      <label htmlFor="content">Treść:</label>
      <textarea id="content" rows="8" cols="20" required />
      <br />
      <button type="submit">Wyślij</button>
    </form>
  );
};

export default EmailForm;
