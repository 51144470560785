import Contents from "../../firebase-db";
import ContentWrapper from "../UI/ContentWrapper";
import GridList from "../UI/GridList";
import Body from "../UI/Body";

//* This function renders all the subjects in the "./RenderSubjectContents.js" file *//
const RenderPage = (props) => {
  const changeSubject = props.setSubject;

  /*FireBase*/
  const subjects = Contents(props.lesson); //?Gets the firebase contents
  /*FireBase END*/

  return (
    <Body>
      {subjects.map((subject) => {
        return (
          <ContentWrapper
            key={subject.id}
            onClick={() => changeSubject(subject.id)}
            touch="no"
            hover="yes"
          >
            <GridList title={subject.thema}>
              <p>{subject.date}</p>
            </GridList>
          </ContentWrapper>
        );
      })}
    </Body>
  );
};

export default RenderPage;
