//*Components*//
import Nav from "./components/NAV/Nav";
import SideBar from "./components/NAV/SideBar";
import RenderPage from "./components/PAGES/RenderPage";
import RenderSubjectContents from "./components/PAGES/RenderSubjectContents";

//*Hooks*//
import { useEffect, useState } from "react";

//*App*//
function App() {
  useEffect(() => {
    document
      .querySelector(":root")
      .style.setProperty("--main", localStorage.getItem("mainColor"));

    document
      .querySelector(":root")
      .style.setProperty("--sec", localStorage.getItem("secColor"));

    document
      .querySelector(":root")
      .style.setProperty("--comp", localStorage.getItem("compColor"));
  }, []);

  //*States*//
  const [sideIsOpen, setSideIsOpen] = useState(false); //?Decides weather to render the side menu or not
  const [lesson, setLesson] = useState("JavaScript"); //?Sets the current lesson. Here you can set the default lesson
  const [subject, setSubject] = useState(""); //?Sets the current lesson. If empty string no subject selected

  //*Handlers*//
  const handle = {
    side: () => {
      setSideIsOpen(!sideIsOpen);
    },
    lesson: (sub) => {
      setLesson(sub);
      handle.side(); //? Closes the side menu after you choose a lesson
    },
    subject: (sub) => setSubject(sub),
  };

  //*JSX*//
  return (
    <>
      {sideIsOpen ? (
        <SideBar sideIsOpen={handle.side} changeLesson={handle.lesson} />
      ) : subject ? (
        <RenderSubjectContents
          setSubject={handle.subject}
          subject={subject}
          lesson={lesson}
        />
      ) : (
        <>
          <Nav sideIsOpen={handle.side} lesson={lesson} />
          <RenderPage lesson={lesson} setSubject={handle.subject} />
        </>
      )}
    </>
  );
}

export default App;
