import "./ContentWrapper.css";

const ContentWrapper = (props) => {
  return (
    <div
      className={`content-wrapper ${props.touch === "no" && props.touch} ${
        props.hover === "yes" && props.hover
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export default ContentWrapper;
