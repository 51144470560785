const Body = (props) => {
  const checkStyle = (style) => (typeof style != "string" ? "inherit" : style);

  const styles = {
    width: "100vw",
    height: "100vh",
    color: checkStyle(props.color),
    backgroundColor: checkStyle(props.backgroundColor),
  };
  return (
    <div style={styles} className="body">
      {props.children}
    </div>
  );
};

export default Body;
