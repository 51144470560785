import Options from "./Options";
import Subject from "./Subject";
import Contents from "../../firebase-db";
import Body from "../UI/Body";
import NavBar from "../UI/NavBar";
import GridList from "../UI/GridList";

import { FaBars } from "react-icons/fa";

import { useState } from "react";

const SideBar = (props) => {
  const closeSideBar = props.sideIsOpen;
  const lessonHandler = props.changeLesson;

  const [option, setOption] = useState("");

  const changeOptionHandler = (opt) => setOption(opt);

  //*FireBase*//
  const lessons = Contents();
  //*FireBase END*//

  return (
    <Body backgroundColor="var(--comp)" color="var(--main)">
      <NavBar>
        <FaBars className="icon-menu" onClick={closeSideBar} />
        <h1 className="title">Opcje</h1>
      </NavBar>

      <GridList title="Lekcje">
        {lessons.map((lesson) => (
          <Subject
            key={lesson.id}
            lessonId={lesson.id}
            lessonName={lesson.name}
            lessonHandler={lessonHandler}
          />
        ))}
      </GridList>

      <center>
        <Options option={option} changeOption={changeOptionHandler} />
      </center>
    </Body>
  );
};

export default SideBar;
