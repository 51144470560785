import { IoMdExit } from "react-icons/io";
import RenderFraction from "./RenderFraction";
import NavBar from "../UI/NavBar";
import Body from "../UI/Body";

//* The page that shows up after selecting a lesson *//
const RenderSubjectContents = (props) => {
  const close = props.setSubject;
  const lesson = props.lesson;
  const subject = props.subject;

  return (
    <Body>
      <NavBar backgroundColor="var(--main)">
        <IoMdExit className="icon-menu" onClick={() => close("")} />
        <h1 className="title">{subject}</h1>
      </NavBar>
      <RenderFraction lesson={lesson} subject={subject} />
    </Body>
  );
};

export default RenderSubjectContents;
