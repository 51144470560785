import ChangeColors from "./ChangeColor";
import EmailForm from "../UI/EmailForm";
import OptionContentWrapper from "../UI/OptionContentWrapper";

const OptionContent = (props) => {
  const option = props.option;

  switch (option) {
    case "mail":
      return (
        <OptionContentWrapper>
          <EmailForm />
        </OptionContentWrapper>
      );
    case "settings":
      return (
        <OptionContentWrapper>
          <ChangeColors />
        </OptionContentWrapper>
      );
    case "visual":
      return (
        <OptionContentWrapper>
          <p>Autor: Juluś</p>
          <p>Wersja: 0.3</p>
        </OptionContentWrapper>
      );
    default:
      return;
  }
};

export default OptionContent;
