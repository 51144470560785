import "./ChangeColor.css";
import { MdDone } from "react-icons/md";
import { useState } from "react";

const ChangeColors = () => {
  const [newMain, setNewMain] = useState("#2c2c2c");
  const [newSec, setNewSec] = useState("#dddddd");
  const [newComp, setNewComp] = useState("#be8815");

  const submitColors = (event) => {
    event.preventDefault();
    localStorage.setItem("mainColor", newMain);
    localStorage.setItem("secColor", newSec);
    localStorage.setItem("compColor", newComp);
    document.querySelector(":root").style.setProperty("--main", newMain);
    document.querySelector(":root").style.setProperty("--sec", newSec);
    document.querySelector(":root").style.setProperty("--comp", newComp);
  };

  const changeValueMain = (event) => {
    setNewMain(event.target.value);
  };
  const changeValueSec = (event) => {
    setNewSec(event.target.value);
  };
  const changeValueComp = (event) => {
    setNewComp(event.target.value);
  };

  return (
    <form className="color-picker__form" onSubmit={submitColors}>
      <section>
        <label htmlFor="main-color-picker">main</label>
        <input
          type="color"
          className="color-picker"
          id="main-color-picker"
          value={newMain}
          onChange={changeValueMain}
        ></input>
      </section>
      <section>
        <label htmlFor="sec-color-picker">sec</label>
        <input
          type="color"
          className="color-picker"
          id="sec-color-picker"
          defaultValue={newSec}
          onChange={changeValueSec}
        ></input>
      </section>
      <section>
        <label htmlFor="comp-color-picker">comp</label>
        <input
          type="color"
          className="color-picker"
          id="comp-color-picker"
          defaultValue={newComp}
          onChange={changeValueComp}
        ></input>
      </section>
      <button type="submit" className="color-picker__button">
        <MdDone />
      </button>
    </form>
  );
};

export default ChangeColors;
